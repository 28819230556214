<template>
    <div class="login_page">
        <div class="login_cont ">
            <div class="content">
                <div class="loginBox">
                    <div class="title display">学员登录</div>
                    <div class="formData">
                        <div class="list flex-center">
                            <img class="icon" src="@/assets/img/phone.png" alt="">
                            <input @blur="blurPhone" @input="onPhone" maxlength="11" type="text" placeholder="请输入手机号">
                            <div v-if="phoneIsShow" class=" error">请输入您的手机号</div>
                        </div>
                        <div class="list flex-center">
                            <img class="icon" src="@/assets/img/password.png" alt="">
                            <input @blur="blurPassword" @input="onPassword" type="password" placeholder="请输入密码">
                            <div v-if="passwordeIsShow" class="error">请输入密码</div>
                        </div>
                        <div @click="loginBtn" class="loginBtn pointer display">登录</div>
                        <div class="hint">注：只有果尔佳学校学员才能登录</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            phoneIsShow: false,
            passwordeIsShow: false,
            params:{
                phone:"",
                password:""
            }
        }
    },
    mounted(){
        document.documentElement.scrollTop = 0;
    },
    methods:{
        // 监听手机号输入
        onPhone(e){
            this.params.phone = e.target.value
        },
        // 监听密码输入
        onPassword(e) {
            this.params.password = e.target.value
        },
         // 手机号输入框失去焦点
        blurPhone(){
            if (this.params.phone == ''){
                this.phoneIsShow = true
            }else{
                this.phoneIsShow = false
            }
        },
        // 密码输入框失去焦点
        blurPassword() {
            if (this.params.password == '') {
                this.passwordeIsShow = true
            } else {
                this.passwordeIsShow = false
            }
        },
        // 点击登录
        loginBtn() {
            if (!/^[0-9]{11}$/.test(this.params.phone)) {
                return this.$message.error('请正确输入手机号码');
            }
            this.api.loginApi(this.params).then(res=>{
                if(res.code == 0){
                    this.$router.go(-1)
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                    this.$message.success('登录成功');
                     localStorage.setItem('time',new Date().getTime())
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>


    .login_page{
        .login_cont{
            width: 100%;
            height: 720px;
            background: url("../../assets/img/loginBg.png") no-repeat;
            background-size: 100% 100%; 
            .content{
                display: flex;
                justify-content: flex-end;
            }
            .loginBox{
                width: 400px;
                height: 343px;
                background: #FFFFFF;
                border-radius: 5px;
                margin-top: 180px;
                margin-right: 80px;
                .title{
                    height: 25px;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #023D7F;
                    margin-top: 40px;
                }
                .formData{
                    padding-left: 34px;
                    box-sizing: border-box;
                    margin-top: 32px;
                    .list{
                        width: 330px;
                        height: 46px;
                        background: #FFFFFF;
                        border: 1px solid #DEDEDE;
                        border-radius: 5px;
                        position: relative;
                        margin-bottom: 20px;
                        .icon{
                            width: 46px;
                            height: 44px;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        input{
                            width: 268px;
                            height: 40px;
                            padding-left: 58px;
                            font-size: 16px;
                        }
                        .error{
                            color: #ff4949;
                            font-size: 12px;
                            position: absolute;
                            left: 0;
                            bottom: -19px;
                        }
                    }
                    .loginBtn{
                        width: 330px;
                        height: 46px;
                        background: #FF881E;
                        border-radius: 5px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-bottom: 20px;
                    }
                    .hint{
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #888888;
                    }
                }
            }
        }
    }
</style>